// hooks/useLeaveSummary.js
import { useState, useEffect } from 'react';
import { fetchLeaveSummary } from '../services/leaveService';

export const useLeaveSummary = (userId, leaveTypeId, finYear) => {
    const [summary, setSummary] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadSummary = async () => {
            setLoading(true);
            try {
                const data = await fetchLeaveSummary(userId, leaveTypeId, finYear);
                setSummary(data);
                setError(null);
            } catch (err) {
                console.error("Error in useLeaveSummary:", err);
                // setError('İzin özeti yüklenirken bir hata oluştu.');
                setSummary(null);
            } finally {
                setLoading(false);
            }
        };

        loadSummary();
    }, [userId, leaveTypeId, finYear]);

    return { summary, loading, error };
};