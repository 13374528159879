import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "totalBalanceMinutes", "usedBalanceMinutes",
        "totalBalanceFields", "totalBalanceDaysField", "totalBalanceHoursField",
        "usedBalanceFields", "usedBalanceDaysField", "usedBalanceHoursField"
    ]

    connect() {
        this.updateTotalBalanceMinutes()
        this.updateUsedBalanceMinutes()
        this.toggleTotalBalanceFields({ target: this.element.querySelector('[name*="total_balance_unit"]') })
        this.toggleUsedBalanceFields({ target: this.element.querySelector('[name*="used_balance_unit"]') })
    }

    toggleTotalBalanceFields(event) {
        const unit = event.target.value
        if (unit === 'days') {
            this.totalBalanceDaysFieldTarget.style.display = 'block'
            this.totalBalanceHoursFieldTarget.style.display = 'none'
        } else {
            this.totalBalanceDaysFieldTarget.style.display = 'none'
            this.totalBalanceHoursFieldTarget.style.display = 'block'
        }
        this.updateTotalBalanceMinutes()
    }
    toggleUsedBalanceFields(event) {
        const unit = event.target.value
        if (unit === 'days') {
            this.usedBalanceDaysFieldTarget.style.display = 'block'
            this.usedBalanceHoursFieldTarget.style.display = 'none'
        } else {
            this.usedBalanceDaysFieldTarget.style.display = 'none'
            this.usedBalanceHoursFieldTarget.style.display = 'block'
        }
        this.updateUsedBalanceMinutes()
    }

    updateTotalBalanceMinutes() {
        const unit = this.element.querySelector('[name*="total_balance_unit"]').value
        let totalMinutes = 0

        if (unit === 'days') {
            const days = parseInt(this.totalBalanceDaysFieldTarget.querySelector('input').value) || 0
            totalMinutes = days * 24 * 60
        } else {
            const hours = parseInt(this.totalBalanceHoursFieldTarget.querySelector('input[name*="total_balance_hours"]').value) || 0
            const minutes = parseInt(this.totalBalanceHoursFieldTarget.querySelector('input[name*="total_balance_remaining_minutes"]').value) || 0
            totalMinutes = (hours * 60) + minutes
        }

        this.totalBalanceMinutesTarget.value = totalMinutes
    }

    updateUsedBalanceMinutes() {
        const unit = this.element.querySelector('[name*="used_balance_unit"]').value
        let totalMinutes = 0

        if (unit === 'days') {
            const days = parseInt(this.usedBalanceDaysFieldTarget.querySelector('input').value) || 0
            totalMinutes = days * 24 * 60
        } else {
            const hours = parseInt(this.usedBalanceHoursFieldTarget.querySelector('input[name*="used_balance_hours"]').value) || 0
            const minutes = parseInt(this.usedBalanceHoursFieldTarget.querySelector('input[name*="used_balance_remaining_minutes"]').value) || 0
            totalMinutes = (hours * 60) + minutes
        }

        this.usedBalanceMinutesTarget.value = totalMinutes
    }
}