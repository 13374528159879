// app/javascript/controllers/leave_type_filter_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "leaveRow", "leaveBalanceRow" ]

    connect() {
        this.filterLeaves()
    }

    filterLeaves() {
        const selectedLeaveTypeId = this.element.value

        if (selectedLeaveTypeId) {
            this.showSelectedLeaveType(selectedLeaveTypeId)
        } else {
            this.showAnnualLeave()
        }
    }

    showSelectedLeaveType(leaveTypeId) {
        this.leaveRowTargets.forEach(row => {
            row.style.display = row.dataset.leaveTypeId === leaveTypeId ? '' : 'none'
        })

        this.leaveBalanceRowTargets.forEach(row => {
            row.style.display = row.dataset.leaveTypeId === leaveTypeId ? '' : 'none'
        })
    }

    showAnnualLeave() {
        const annualLeaveType = this.element.querySelector('option[data-is-annual-leave="true"]')
        if (annualLeaveType) {
            this.showSelectedLeaveType(annualLeaveType.value)
        } else {
            this.showAllLeaveTypes()
        }
    }

    showAllLeaveTypes() {
        this.leaveRowTargets.forEach(row => row.style.display = '')
        this.leaveBalanceRowTargets.forEach(row => row.style.display = '')
    }
}