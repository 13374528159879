// components/common/Skeleton/SkeletonTable.jsx
import React from 'react';
import Skeleton from './Skeleton';

const SkeletonTable = ({ rows, columns }) => {
    return (
        <table className="table table-striped table-md">
            <thead>
            <tr>
                {Array(columns).fill().map((_, index) => (
                    <th key={index}>
                        <Skeleton width={100} height={20} />
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {Array(rows).fill().map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {Array(columns).fill().map((_, colIndex) => (
                        <td key={colIndex}>
                            <Skeleton width={80} height={20} />
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default SkeletonTable;