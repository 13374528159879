import React, { useState, useEffect } from 'react';
import LeaveToolbar from './LeaveToolbar';
import LeaveSummary from './LeaveSummary';
import LeaveTable from './LeaveTable';
import { fetchLeaveTypes, fetchLeaveSummary, fetchLeaves } from '../../services/leaveService';

const LeaveFilter = ({ userId, initialFinYears, initialFinYear }) => {
    const [selectedLeaveType, setSelectedLeaveType] = useState(null);
    const [selectedFinYear, setSelectedFinYear] = useState(initialFinYear);
    const [activeTab, setActiveTab] = useState('summary');
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [leaveSummary, setLeaveSummary] = useState(null);
    const [leaves, setLeaves] = useState([]);

    useEffect(() => {
        loadLeaveTypes();
    }, [activeTab]);

    useEffect(() => {
        if (selectedLeaveType) {
            if (activeTab === 'summary') {
                loadLeaveSummary();
            } else {
                loadLeaves();
            }
        }
    }, [activeTab, selectedLeaveType, selectedFinYear]);

    const loadLeaveTypes = async () => {
        try {
            const types = await fetchLeaveTypes(activeTab === 'summary');
            setLeaveTypes(types);
            const annualLeaveType = types.find(lt => lt.is_annual_leave);
            if (annualLeaveType) {
                setSelectedLeaveType(annualLeaveType.id);
            } else if (types.length > 0) {
                setSelectedLeaveType(types[0].id);
            }
        } catch (error) {
            console.error("Error loading leave types:", error);
        }
    };

    const loadLeaveSummary = async () => {
        if (selectedLeaveType) {
            try {
                const summary = await fetchLeaveSummary(userId, selectedLeaveType, selectedFinYear);
                setLeaveSummary(summary);
            } catch (error) {
                console.error("Error loading leave summary:", error);
            }
        }
    };

    const loadLeaves = async () => {
        if (selectedLeaveType) {
            try {
                const fetchedLeaves = await fetchLeaves(userId, selectedLeaveType, selectedFinYear);
                setLeaves(fetchedLeaves);
            } catch (error) {
                console.error("Error loading leaves:", error);
            }
        }
    };

    const handleFilterChange = (leaveTypeId, finYear) => {
        setSelectedLeaveType(leaveTypeId);
        setSelectedFinYear(finYear);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleConsensusClick = () => {
        if (selectedLeaveType && selectedFinYear) {
            const url = `/leaves/yearly_leave_summary?user_id=${userId}&leave_type_id=${selectedLeaveType}&fin_year=${selectedFinYear}&format=pdf`;
            window.open(url, '_blank');
        } else {
            alert('Lütfen bir izin tipi ve yıl seçin.');
        }
    };

    return (
        <div>
            <LeaveToolbar
                leaveTypes={leaveTypes}
                finYears={initialFinYears}
                selectedLeaveType={selectedLeaveType}
                selectedFinYear={selectedFinYear}
                onFilterChange={handleFilterChange}
                onTabChange={handleTabChange}
                activeTab={activeTab}
                showAllLeaveTypesOption={activeTab === 'leaves'}
                onConsensusClick={handleConsensusClick}
            />

            {activeTab === 'summary' && (
                <LeaveSummary
                    userId={userId}
                    selectedLeaveType={selectedLeaveType}
                    selectedFinYear={selectedFinYear}
                    summary={leaveSummary}
                />
            )}
            {activeTab === 'leaves' && (
                <LeaveTable
                    userId={userId}
                    selectedLeaveType={selectedLeaveType}
                    selectedFinYear={selectedFinYear}
                    leaves={leaves}
                />
            )}
        </div>
    );
};

export default LeaveFilter;