import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import Accessibility from "highcharts/modules/accessibility";

Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);

export default class extends Controller {
    static targets = ["submitButton"];


    submit(event) {
        event.preventDefault();
        const formData = new FormData(this.element);
        const searchParams = new URLSearchParams();

        formData.forEach((value, key) => {
            searchParams.append(key, value);
        });

        const paramsObject = {
            user_ids: formData.getAll('user_ids[]').filter(id => id !== ""),
            branch_ids: formData.getAll('branch_ids[]').filter(id => id !== ""),
            department_ids: formData.getAll('department_ids[]').filter(id => id !== ""),
            job_position_ids: formData.getAll('job_position_ids[]').filter(id => id !== "")
        };;

        console.log(paramsObject);


        const excelElement = document.querySelector('[data-excel="true"]');
        if (excelElement) {
            let params = JSON.parse(excelElement.getAttribute('data-params').replace(/&quot;/g, '"'));
            params.user_ids = paramsObject.user_ids;
            params.branch_ids = paramsObject.branch_ids;
            params.department_ids = paramsObject.department_ids;
            params.job_position_ids = paramsObject.job_position_ids;
            excelElement.setAttribute('data-params', JSON.stringify(params));
        } else {
            console.error("No element found with data-excel='true'");
        }

        Rails.ajax({
            url: `${this.element.action}?${searchParams.toString()}`,
            type: this.element.method || "GET",
            contentType: "application/json",
            success: (response) => {
                this.createQuestionCharts(response);
            },
            error: (err) => {
                console.error("Form submission error:", err);
            }
        });
    }

    createQuestionCharts(data) {
        if (!data || !data.question_completion_data || Object.keys(data.question_completion_data).length === 0) {
            const chartContainer = document.querySelector('[data-highcharts-target="dynamicQuestionPieChart"]');
            if (chartContainer) {
                while (chartContainer.firstChild) {
                    chartContainer.removeChild(chartContainer.firstChild);
                }
            }
            return false;
        }

        const chartContainer = document.querySelector('[data-highcharts-target="dynamicQuestionPieChart"]');

        while (chartContainer.firstChild) {
            chartContainer.removeChild(chartContainer.firstChild);
        }

        Object.keys(data.question_completion_data).forEach(questionTitle => {
            const questionData = data.question_completion_data[questionTitle];
            const {"Doğru Cevap": correct, "Yanlış Cevap": incorrect, "Toplam Cevaplar": total} = questionData;

            const wrapperDiv = document.createElement('div');
            wrapperDiv.style.marginBottom = '40px';

            const chartDiv = document.createElement('div');
            chartDiv.style.marginBottom = '20px';

            const detailsDiv = document.createElement('div');

            const totalAnswers = document.createElement('p');
            totalAnswers.textContent = "Toplam Cevaplar: " + total;

            const correctAnswers = document.createElement('p');
            correctAnswers.textContent = "Doğru Cevaplar: " + correct;

            const incorrectAnswers = document.createElement('p');
            incorrectAnswers.textContent = "Yanlış Cevaplar: " + incorrect;

            detailsDiv.appendChild(totalAnswers);
            detailsDiv.appendChild(correctAnswers);
            detailsDiv.appendChild(incorrectAnswers);

            wrapperDiv.appendChild(chartDiv);
            wrapperDiv.appendChild(detailsDiv);

            chartContainer.appendChild(wrapperDiv);

            this.renderPieChart(chartDiv, questionTitle, questionData);
            wrapperDiv.scrollIntoView({behavior: 'smooth'});
        });
    }

    renderPieChart(chartDiv, questionTitle, questionData) {
        Highcharts.chart(chartDiv, {
            chart: {
                type: 'pie'
            },
            title: {
                text: `${questionTitle} Cevap Verileri`
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> ({point.y})'
            },
            series: [{
                name: 'Toplam',
                colorByPoint: true,
                data: [
                    {name: "Doğru Cevap", y: questionData["Doğru Cevap"]},
                    {name: "Yanlış Cevap", y: questionData["Yanlış Cevap"]}
                ]
            }],
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.percentage:.1f} %'
                    }
                }
            },
        });
    }
}
