import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["leaveDayFields", "durationFields", "negativeBalanceLimit"]

    connect() {
        this.toggleLeaveDayFields()
        this.toggleDurationFields()
        this.toggleNegativeBalanceLimit()
    }

    toggleLeaveDayFields() {
        const leaveDayUnit = this.element.querySelector('#leave_type_leave_day_unit').value
        this.toggleFields(this.leaveDayFieldsTarget, leaveDayUnit)
    }

    toggleDurationFields() {
        const durationUnit = this.element.querySelector('#leave_type_duration_unit').value
        this.toggleFields(this.durationFieldsTarget, durationUnit)
    }


    toggleNegativeBalanceLimit() {
        const negativeBalanceType = this.element.querySelector('#leave_type_negative_balance_type').value
        if (negativeBalanceType === "limited_negative_balance") {
            this.negativeBalanceLimitTarget.style.display = "block"
        } else {
            this.negativeBalanceLimitTarget.style.display = "none"
        }
    }

    toggleFields(target, unit) {
        const daysFields = target.querySelectorAll('.days-field')
        const hoursFields = target.querySelectorAll('.hours-field')

        if (unit === 'days' || unit === 'in_days') {
            daysFields.forEach(el => el.style.display = 'block')
            hoursFields.forEach(el => el.style.display = 'none')
        } else {
            daysFields.forEach(el => el.style.display = 'none')
            hoursFields.forEach(el => el.style.display = 'block')
        }
    }
}