import React from 'react';
import { useLeaveData } from '../../hooks/useLeaveData';
import SkeletonTable from '../common/Skeleton/SkeletonTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const LeaveTable = ({ userId, selectedLeaveType, selectedFinYear }) => {
    const { leaves, loading, error } = useLeaveData(userId, selectedLeaveType, selectedFinYear);

    console.log("useLeaveData result:", { leaves, loading, error });

    if (loading) return <SkeletonTable rows={5} columns={6} />;
    if (error) return <div>Hata: {error}</div>;
    if (!leaves || leaves.length === 0) {
        return (
            <div className="alert alert-info">
                Seçilen kriterlere uygun izin kaydı bulunamadı.
            </div>
        );
    }

    // Durum değerlerini insan tarafından okunabilir hale getiren fonksiyon
    const humanReadableStatus = (status) => {
        const statusMap = {
            'awaiting_approval': 'Onay Bekliyor',
            'approved': 'Onaylandı',
            'rejected': 'Reddedildi',
            'document_delivery_awaiting': 'Belge Teslimi Bekleniyor',
            // Diğer durumları buraya ekleyin
        };
        return statusMap[status] || status;
    };

    return (
        <table className="table table-striped table-md">
            <thead>
            <tr>
                <th>İzin Türü</th>
                <th>Gün Sayısı</th>
                <th>Durum</th>
                <th>Başlangıç Tarihi</th>
                <th>Bitiş Tarihi</th>
                <th>İşlemler</th>
            </tr>
            </thead>
            <tbody>
            {leaves.map(leave => (
                <tr key={leave.id}>
                    <td>{leave.leave_type.title}</td>
                    <td>{leave.formatted_days_off_count}</td>
                    <td>{humanReadableStatus(leave.status)}</td>
                    <td>{new Date(leave.start_date).toLocaleDateString()}</td>
                    <td>{new Date(leave.end_date).toLocaleDateString()}</td>
                    <td>
                        {!leave.leave_type.hide_pdf && (
                            <a href={`/leaves/${leave.id}.pdf`} target="_blank" rel="noopener noreferrer" className="btn btn-icon btn-sm btn-light">
                                <FontAwesomeIcon icon={faFilePdf} /> PDF
                            </a>
                        )}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default LeaveTable;