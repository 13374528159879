// app/javascript/controllers/leave_type_balance_display_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["balanceDisplay"]

    updateBalanceDisplay(event) {
        const leaveTypeId = event.target.value
        fetch(`/users/${this.data.get("userId")}/leave_type_balance_display?leave_type_id=${leaveTypeId}`)
            .then(response => response.text())
            .then(html => {
                this.balanceDisplayTarget.innerHTML = html
            })
    }
}