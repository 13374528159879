import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["carryoverType", "carryoverLimitField", "carryoverExpiryMonthsField"]

    connect() {
        this.updateFields()
    }

    updateFields() {
        const selectedType = this.carryoverTypeTarget.value
        this.toggleCarryoverLimitField(selectedType)
        this.toggleCarryoverExpiryMonthsField(selectedType)
    }

    toggleCarryoverLimitField(selectedType) {
        const isVisible = ['limited_carryover', 'limited_carryover_with_expiry'].includes(selectedType)
        this.carryoverLimitFieldTarget.style.display = isVisible ? 'block' : 'none'
    }

    toggleCarryoverExpiryMonthsField(selectedType) {
        this.carryoverExpiryMonthsFieldTarget.style.display =
            selectedType === 'limited_carryover_with_expiry' ? 'block' : 'none'
    }
}