import { Controller } from "stimulus"
import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = [ "leaveTypeSelect", "finYearSelect", "errorMessage", "summaryTab", "leavesTableTab" ]

    connect() {
        super.connect()
        this.filterLeaves()
    }

    filterLeaves() {
        const leaveTypeId = this.leaveTypeSelectTarget.value
        const finYear = this.finYearSelectTarget.value

        this.stimulate('LeaveFilterReflex#filter_leaves', leaveTypeId || "all", finYear)
            .then(() => {
                this.clearError()
                this.updateTabs()
            })
            .catch((error) => {
                console.error('An error occurred:', error)
                this.showError(error.message || 'An error occurred')
            })
    }

    showError(message) {
        if (this.hasErrorMessageTarget) {
            this.errorMessageTarget.innerHTML = `<div class='alert alert-danger'>${message}</div>`
        }
    }

    clearError() {
        if (this.hasErrorMessageTarget) {
            this.errorMessageTarget.innerHTML = ''
        }
    }

    updateTabs() {
        if (this.hasSummaryTabTarget && this.hasLeavesTableTabTarget) {
            this.summaryTabTarget.classList.remove('active', 'show')
            this.leavesTableTabTarget.classList.add('active', 'show')
        }
    }
}