import React, { useState, useEffect } from 'react';
import { fetchLeaves } from '../services/leaveService';

export const useLeaveData = (userId, leaveTypeId, finYear) => {
    const [leaves, setLeaves] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadLeaves = async () => {
            try {
                setLoading(true);
                const data = await fetchLeaves(userId, leaveTypeId, finYear);
                console.log("Fetched leaves data:", data);
                setLeaves(data);
                setError(null);
            } catch (err) {
                console.error("Error in useLeaveData:", err);
                setError('İzin verileri yüklenirken bir hata oluştu.');
                setLeaves([]);
            } finally {
                setLoading(false);
            }
        };

        loadLeaves();
    }, [userId, leaveTypeId, finYear]);

    return { leaves, loading, error };
};