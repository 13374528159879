import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["select", "sc430", "makim", "passgage", "passgage_qr", "makim_qr", "nfc"]

    connect() {
        this.selected()
    }

    selected() {
        this.hideFields()
        switch (this.selectTarget.value) {
            case 'sc430':
                this.sc430Target.classList.remove('d-none')
                break;
            case 'makim_v1':
                this.makimTarget.classList.remove('d-none')
                break;
            case 'makim_v2':
                this.makimTarget.classList.remove('d-none')
                break;
            case 'passgage':
                this.passgageTarget.classList.remove('d-none')
                break;
            case 'passgage_qr':
                this.passgageTarget.classList.remove('d-none')
                break;
            case 'makim_qr':
                this.makimTarget.classList.remove('d-none')
                break;
            case 'nfc':
                this.passgageTarget.classList.remove('d-none')
                break;

        }
    }

    hideFields() {
        this.passgageTarget.classList.add('d-none')
        this.makimTarget.classList.add('d-none')
        this.sc430Target.classList.add('d-none')
    }
}