// form_controller.js
import {Controller} from "stimulus"
import Rails from "@rails/ujs"
import Highcharts from "highcharts"
import Exporting from "highcharts/modules/exporting"
import ExportData from "highcharts/modules/export-data"
import Accessibility from "highcharts/modules/accessibility"

Exporting(Highcharts)
ExportData(Highcharts)
Accessibility(Highcharts)

export default class extends Controller {
    static targets = ["branchSelect", "departmentSelect", "jobPositionSelect", "submitButton"];


    submit(event) {
        event.preventDefault()
        const formData = new FormData(this.element);
        const searchParams = new URLSearchParams();


        formData.forEach((value, key) => {
            searchParams.append(key, value);
        });

        Rails.ajax({
            url: `${this.element.action}?${searchParams.toString()}`,
            type: this.element.method || "GET",
            contentType: "application/json",
            success: (response) => {
                this.createChart(response)
            },
            error: (err) => {
                console.error("Form submission error:", err)
            }
        })

    }

    createChart(data) {
        if (!data || !data.survey_completion_data || Object.keys(data.survey_completion_data).length === 0) {
            const chartContainer = document.querySelector('[data-highcharts-target="dynamicPieChart"]');
            if (chartContainer) {
                while (chartContainer.firstChild) {
                    chartContainer.removeChild(chartContainer.firstChild);
                }
            }
            return false;
        }

        const entries = Object.entries(data.survey_completion_data);
        for (const [key, value] of entries) {
            if (!value.hasOwnProperty('Tamamlayan') || !value.hasOwnProperty('Tamamlamayan')) {
                const chartContainer = document.querySelector('[data-highcharts-target="dynamicPieChart"]');
                if (chartContainer) {
                    while (chartContainer.firstChild) {
                        chartContainer.removeChild(chartContainer.firstChild);
                    }
                }
                return false;
            }
        }

        const chartContainer = document.querySelector('[data-highcharts-target="dynamicPieChart"]');

        while (chartContainer.firstChild) {
            chartContainer.removeChild(chartContainer.firstChild);
        }

        Object.keys(data.survey_completion_data).forEach(branchName => {
            const modelData = data.survey_completion_data[branchName];
            const {Toplam, Tamamlayan, Tamamlamayan} = modelData;

            const wrapperDiv = document.createElement('div');
            wrapperDiv.style.marginBottom = '40px'; // Adjust the spacing as needed

            const chartDiv = document.createElement('div');
            chartDiv.style.marginBottom = '20px';

            const detailsDiv = document.createElement('div');

            const participantCount = document.createElement('p');
            participantCount.textContent = "Katılımcı Sayısı: " + Toplam;

            const completedCount = document.createElement('p');
            completedCount.textContent = "Tamamlayan Kişi Sayısı: " + Tamamlayan;

            const incompleteCount = document.createElement('p');
            incompleteCount.textContent = "Tamamlamayan Kişi Sayısı: " + Tamamlamayan;

            detailsDiv.appendChild(participantCount);
            detailsDiv.appendChild(completedCount);
            detailsDiv.appendChild(incompleteCount);

            wrapperDiv.appendChild(chartDiv);
            wrapperDiv.appendChild(detailsDiv);

            chartContainer.appendChild(wrapperDiv);

            delete modelData.Toplam;
            this.renderPieChart(chartDiv, branchName, modelData);
            wrapperDiv.scrollIntoView({behavior: 'smooth'});
        });
    }


    renderPieChart(chartDiv, modelName, modelData) {
        Highcharts.chart(chartDiv, {
            chart: {
                type: 'pie'
            },
            title: {
                text: `${modelName} Tamamlanma Verileri`
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> ({point.y})'
            },
            series: [{
                name: 'Toplam',
                colorByPoint: true,
                data: Object.keys(modelData).map(key => ({
                    name: key,
                    y: modelData[key]
                }))
            }],
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.percentage:.1f} %'
                    }
                }
            },
        });
    }
}
